import React from "react";
import { Label, Dropdown as SUIDropdown } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI select component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  selection: boolean
 *  search: boolean
 *  options: {
 *    key: *,
 *    text: string,
 *    value: string,
 *    description?: string
 *  }
 * }} props
 */
export function Dropdown({
  name,
  required,
  formLabel,
  selection,
  search,
  options,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <>
          {formLabel && (
            <label
              basic
              // Semantic UI label styles
              style={{
                display: "block",
                margin: "0 0 .28571429rem 0",
                color: "rgba(0, 0, 0, .87)",
                fontSize: ".92857143em",
                fontWeight: "700",
              }}
            >
              {formLabel}{" "}
              <span style={{ color: "#db2828" }}>{required && " *"}</span>
            </label>
          )}

          <SUIDropdown
            {...props}
            {...field}
            selection={selection ?? true}
            search={search ?? true}
            options={options ? options : []}
            onChange={(e, data) => {
              field.onChange(data.value);

              if (props.onChange) {
                props.onChange(e, data);
              }
            }}
            error={error && true}
            noResultsMessage="No results"
          />

          {error && (
            <Label
              basic
              color="red"
              pointing="above"
              style={{ display: "block", width: "fit-content" }}
            >
              {error.message}
            </Label>
          )}
        </>
      )}
    />
  );
}
