import { useState } from 'react';
import { Input, Icon, FormField, Form, Button} from 'semantic-ui-react';

/**
 * An uncontrolled text input that has the following functionality:
 * 
 * - onSubmit is called when the user presses enter.
 * - The input is cleared when the user clicks the clear button, or presses escape or delete.
 * @param {{
 *  placeholder: string
 *  onSubmit: (inputValue: string) => void
 * }} props
 */
export const TextInput = ({placeholder, onSubmit}) => {
  const [inputValue, setInputValue] = useState('');

  const keyDownHandler = (keyCode) => {
    if (keyCode === 'Escape' || keyCode === 'Delete') {
        handleClear();
    }
  }

  const handleChange = (e) => {
    const newSearchValue = e.target.value;
    setInputValue(newSearchValue ?? "");
    onSubmit(newSearchValue ?? "");
  };

  const handleClear = () => {
    setInputValue('');
    onSubmit('');
  }

  return (
    <Form>
      <FormField>
          <Input
              onKeyDown={(key) => keyDownHandler(key.code)}
              icon='search'
              iconPosition='left'
              placeholder={placeholder}
              value={inputValue}
              onChange={handleChange}
              action={<Button type='button' icon onClick={handleClear}>
                  <Icon name='close' />
              </Button>}
          >   
          </Input>
      </FormField>
    </Form>
  )
}
