import { Table, Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { now } from "moment";
import { useUserAccess } from "./UserAccessManager";
import { openInNewTab } from "../utils/openInNewTab";

const OngoingBookingsTable = ({ bookings }) => {
  const navigate = useNavigate();
  const { isCommunityManager } = useUserAccess();

  const displayStatusLabel = (booking) => {
    var endTime = new Date(booking.bookingEndTime);
    if (new Date() > endTime) {
      return (
        <>
          <Label color="red" content="overdue" icon="clock" />{" "}
          <p>
            {" "}
            by{" "}
            <Moment
              format="h[h] m[m]"
              trim
              duration={endTime}
              date={new Date(now())}
            />
          </p>{" "}
        </>
      );
    } else return <Label className={booking.status}>{booking.status}</Label>;
  };

  return (
    <>
      <Table selectable textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="9">
              Ongoing Bookings List
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            {!isCommunityManager && <Table.HeaderCell>Phone</Table.HeaderCell>}
            <Table.HeaderCell>Vehicle</Table.HeaderCell>
            <Table.HeaderCell>Site</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Due Back</Table.HeaderCell>
            <Table.HeaderCell>Duration</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {bookings.map((booking) => (
            <Table.Row
              key={booking.id}
              onClick={() => navigate(`/bookings/${booking.id}`)}
              onMouseUp={(e) => openInNewTab(e, `bookings/${booking.id}`)}
            >
              {isCommunityManager ? (
                <Table.Cell>
                  {booking.customerFirstName} {booking.customerLastName[0]}
                </Table.Cell>
              ) : (
                <>
                  <Table.Cell>
                    {booking.customerFirstName} {booking.customerLastName}
                  </Table.Cell>
                  <Table.Cell>{booking.customerPhoneNumber}</Table.Cell>
                </>
              )}
              <Table.Cell>
                {booking.vehicleMake} - {booking.vehicleRegistrationPlate}
              </Table.Cell>
              <Table.Cell>{booking.siteName}</Table.Cell>
              <Table.Cell>{displayStatusLabel(booking)}</Table.Cell>
              <Table.Cell>
                <Moment format="llll Z" local>
                  {booking.bookingEndTime}
                </Moment>
              </Table.Cell>
              <Table.Cell>
                <Moment
                  format="H[h]:mm[m]"
                  duration={booking.bookingStartTime}
                  date={booking.bookingEndTime}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default OngoingBookingsTable;
