import { useState, useEffect, useCallback } from "react";
import { Table, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api/index";
import { Loader } from "../components/Loader";
import { getPaginatedItems, Pagination } from "../components/Pagination";
import { TextInput } from "../components/TextInput";
import { isLoading } from "../utils/isLoading";
import { ButtonGroup } from "../components/ButtonGroup";
import { openInNewTab } from "../utils/openInNewTab";
import { useUserAccess } from "../components/UserAccessManager";
import CreateProductDropdown from "../components/modals/create-product/CreateProductDropdown";
import { Dropdown } from "../components/Dropdown";

const Vehicles = () => {
  const navigate = useNavigate();
  const [allVehicles, setAllVehicles] = useState({
    state: "loading",
    value: [],
  });
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [vehicleManufacturers, setVehicleManufacturers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue: "",
    // Capitalised so conversion to and from database values is not required
    vehicleType: "Vehicle",
    vehicleMake: "All",
  });

  const { getAccessTokenSilently } = useAuth0();
  const { isCommunityManager } = useUserAccess();

  const applyFilters = useCallback((allVehicles, filters) => {
    return allVehicles.value.filter((vehicle) => {
      // Search in registration or site name
      const searchMatch =
        filters.searchValue === "" ||
        vehicle.registrationPlate
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase()) ||
        vehicle.site.displayName
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase());

      // Show all vehicles, or only vehicles where vehicle type contains the selected type
      const typeMatch =
        filters.vehicleType === "All" ||
        vehicle.type.includes(filters.vehicleType);

      // Show all vehicles, or only vehicles where vehicle make contains the selected make
      const makeMatch =
        filters.vehicleMake === "All" ||
        vehicle.make.includes(filters.vehicleMake);

      return searchMatch && typeMatch && makeMatch;
    });
  }, []);

  const filterVehicles = useCallback(
    (allVehicles, filters) => {
      const filteredVehicleResults = applyFilters(allVehicles, filters);
      setFilteredVehicles(filteredVehicleResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const fetchedVehicles = await api.getVehicles(accessToken);

    setAllVehicles({ state: "success", value: fetchedVehicles });

    let manufacturers = [
      ...new Set(fetchedVehicles.map((vehicle) => vehicle.make)),
    ];
    setVehicleManufacturers(manufacturers);

    setFilteredVehicles(fetchedVehicles);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    filterVehicles(allVehicles, filters);
  }, [allVehicles, filterVehicles, filters]);

  return (
    <>
      <Grid stackable columns={3}>
        <Grid.Column>
          <TextInput
            placeholder="Registration or site"
            onSubmit={(updatedFilter) =>
              updateFilters({ searchValue: updatedFilter })
            }
          />
        </Grid.Column>

        <Grid.Column>
          <ButtonGroup
            content={["All", "Vehicle", "Charger", "Bike"]}
            onClick={(vehicleType) => updateFilters({ vehicleType })}
            defaultIndex={1}
          />
        </Grid.Column>

        <Grid.Column>
          {!isCommunityManager && <CreateProductDropdown />}
        </Grid.Column>
      </Grid>

      <Loader isLoading={isLoading(allVehicles)}>
        <Table selectable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Registration Plate</Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  label="Make"
                  items={[
                    { key: "all", text: "All", value: "All" },
                    ...vehicleManufacturers.map((make) => ({
                      key: make,
                      text: make,
                      value: make,
                    })),
                  ]}
                  onSelect={(item) =>
                    updateFilters({ vehicleMake: item.value })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Model</Table.HeaderCell>
              <Table.HeaderCell>Site</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPaginatedItems(filteredVehicles, activePage).map((vehicle) => (
              <Table.Row
                key={vehicle.id}
                onClick={() => navigate(`${vehicle.id}`)}
                onMouseUp={(e) => openInNewTab(e, `vehicles/${vehicle.id}`)}
              >
                <Table.Cell>{vehicle.type}</Table.Cell>
                <Table.Cell>{vehicle.registrationPlate}</Table.Cell>
                <Table.Cell>{vehicle.make}</Table.Cell>
                <Table.Cell>{vehicle.model}</Table.Cell>
                <Table.Cell>{vehicle.site.displayName}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Grid stackable>
          <Grid.Column>
            <Pagination
              activePage={activePage}
              onPageChange={(page) => setActivePage(page)}
              itemQuantity={filteredVehicles.length}
            />
          </Grid.Column>
        </Grid>
      </Loader>
    </>
  );
};

export default Vehicles;
