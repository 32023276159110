import React from 'react'
import { Dropdown as SUIDropdown} from 'semantic-ui-react';

/**
 * Creates a dropdown menu with the supplied dropdown items\
 * and calls onSelect when an item in the list is selected
 * @param {{
 *  label: string
 *  items: Array
 *  onSelect: (Object) => void
 * }} props 
 * @returns 
 */
export const Dropdown = ({label, items, onSelect}) => {
  return (
    <SUIDropdown item text={label}>
        <SUIDropdown.Menu>
            {items.map((item) => (
              <SUIDropdown.Item 
                key={item.text} 
                onClick={() => onSelect(item)}
              >
                {item.text}
              </SUIDropdown.Item>
            ))}
        </SUIDropdown.Menu>
    </SUIDropdown>
  )
}
