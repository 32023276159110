const dateOptions = {
  hourCycle: "h24",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "shortOffset",
};
export const dateTimeFormat = new Intl.DateTimeFormat("en-AU", dateOptions);

/**
 * Finds the first non-null time and sets the time to 00:00:00
 * @param {Array} nullableTimes 
 * @returns {Date}
 */
export function nullableTimeToDate (nullableTimes) {
  const nonNullTime = nullableTimes.find(t => t !== null);
  const timeToDate = new Date(nonNullTime);
  timeToDate.setHours(0, 0, 0);

  return timeToDate;
}