import React from "react";
import { default as ReactDateTimePicker } from "react-datetime-picker";
import { Label } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the React DateTime Picker to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 * }} props
 */
export function DateTimePicker({ name, required, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <>
          <ReactDateTimePicker
            {...props}
            {...field}
            disableClock={true}
            format="dd/MM/y h:mm a"
            onChange={(value) => {
              field.onChange(value);

              if (props.onChange) {
                props.onChange(value);
              }
            }}
            value={field.value}
          />
          {error && (
            <Label
              basic
              color="red"
              pointing="above"
              style={{ display: "block", width: "fit-content" }}
            >
              {error.message}
            </Label>
          )}
        </>
      )}
    />
  );
}
