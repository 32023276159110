import { Header, Grid} from 'semantic-ui-react';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import OngoingBookingsTable from  '../components/OngoingBookingsTable';
import { api } from '../api';
import packageInfo from '../../package.json';
import { isLoading } from '../utils/isLoading';
import { Loader } from '../components/Loader';

const Home = () => {
    const [bookings, setBookings] = useState({state: "loading", value: []});
    const version = packageInfo.version;

    const { getAccessTokenSilently } = useAuth0();

    const fetchData = useCallback(async () => { 
        const accessToken = await getAccessTokenSilently();
        const fetchedBookings = await api.getAllBookings('ongoing', accessToken);
        setBookings({state: "success", value: fetchedBookings});
    }, [getAccessTokenSilently])

    useEffect(() => {
        fetchData();

        let timer;

        timer = setInterval(() => {
            const sec = new Date().getSeconds();

            if (sec) return;

            fetchData();
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [fetchData])

    return (
        <>
            <div style={{position: "absolute", right: 50, top: 50}}>
                Admin app version: {version}
            </div>

            <Header as='h1' textAlign='center'>Dashboard</Header>

            <Loader isLoading={isLoading(bookings)}>
                <Grid>
                    <Grid.Column>
                        <OngoingBookingsTable bookings={bookings.value}/>
                    </Grid.Column>
                </Grid>
            </Loader>
        </>
    )
}

export default Home;
